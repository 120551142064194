<template>
  <div class="container-fluid p-0">
    <div class="img-container position-relative">
      <div class="img-overlay position-absolute"></div>
      <img
        class="img-fluid w-100 first-slide"
        src="@/assets/book5.webp"
        alt="Kenwyn Books"
      />
      <div class="bg-text position-absolute text-center">
        <h3 class="text-head">
          <b>Kenwyn Books</b>
        </h3>
        <p class="paraHead mt-3">
          Empowering Seamless Connectivity and Efficiency
        </p>
        <button class="btn buttonService mt-4">
          <a href="/features" class="contactUs">
            <b>FEATURES</b>
          </a>
        </button>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row pt-5 pb-5">
      <div class="col-md-4 col-sm-12 col-lg-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 50rem">
            <div class="card-body">
              <h4 class="card-title pt-5"><b>Web-Based Accounting</b></h4>
              <h6 ALIGN="CENTER" class="sub-card-title">
                Manage your Company Accounts
              </h6>
              <img
                class="img-fluid pt-2"
                src="@/assets/accounting.webp"
                alt="Cardimage1"
              />
              <div class="pt-2 cardcontent1">
                <h6 ALIGN="CENTER"><b>Features</b></h6>

                <ul class="pt-3">
                  <li>- Quotations and Invoices</li>

                  <li>- Sales & Purchase Orders</li>

                  <li>- Credit Notes</li>

                  <li>- Inventory Management</li>

                  <li>- Financial Reporting</li>
                </ul>
              </div>

              <div class="text-center">
                <a
                  href="/features"
                  ALIGN="CENTER"
                  class="btn btn-outline-success"
                >
                  <b>EXPLORE MORE</b>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 50rem">
            <div class="card-body">
              <h4 class="card-title pt-5"><b>Payroll</b></h4>
              <h6 ALIGN="CENTER" class="sub-card-title">Manpower Management</h6>
              <img
                class="img-fluid pt-2"
                src="@/assets/pay.webp"
                alt="Cardimage2"
              />

              <div class="pt-2 cardcontent2">
                <h6 ALIGN="CENTER"><b>Features</b></h6>
                <ul class="pt-3">
                  <li>- Leave Management</li>

                  <li>- Employee Claims Approval</li>

                  <li>- Itemised Payslips</li>

                  <li>- CPF Computation</li>

                  <li>- Attendance Management</li>

                  <li>- Employee Particulars e-Tracking</li>
                </ul>
              </div>
              <div class="text-center">
                <a
                  href="/features"
                  ALIGN="CENTER"
                  class="btn btn-outline-success"
                >
                  <b>EXPLORE MORE</b>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 50rem">
            <div class="card-body">
              <h4 class="card-title pt-5"><b>Corporate Secretary</b></h4>
              <h6 ALIGN="CENTER" class="sub-card-title">
                Registered Filing Agent
              </h6>
              <img
                class="img-fluid pt-2"
                src="@/assets/sample2.webp"
                alt="Card image cap"
              />
              <div class="pt-2 cardcontent3">
                <h6 ALIGN="CENTER"><b>Features</b></h6>

                <ul class="pt-3">
                  <li>- Preparation of secretarial documents</li>

                  <li>- Management of compliance datelines</li>

                  <li>- Automated Compliance Tracking</li>

                  <li>- Multi-Company Management</li>
                </ul>
              </div>
            </div>

            <div class="">
              <div class="text-center">
                <a
                  href="/corporate-secretarial-software"
                  ALIGN="CENTER"
                  class="btn btn-outline-success detailBtn2"
                >
                  <b>EXPLORE MORE</b>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container"></div>
  <div class="container-fluid">
    <div class="row pb-5 pr-5 rowColorTwo">
      <div class="col-md-12 col-sm-12 col-lg-12 rowClr">
        <div>
          <h6 class="conQusTwo pt-5"><b>SOCIAL MEDIA</b></h6>

          <div class="socialMedia">
            <span class="pl-2 socialMediah">
              We're on social media and we'd love you to give us a follow !
              <br />
              Stay connected with Kenwyn Accounting and IT Services. Choose your
              favourite social media channel.
            </span>

            <div class="pt-5 buttonFolow">
              <a
                type="button"
                class="btn btn-light"
                href="https://www.facebook.com/kenwynaccountinganditservices/"
                target="_blank"
              >
                <b>
                  FOLLOW US &nbsp;
                  <i class="fa fa-facebook-square" style="font-size: 20px"></i>
                </b>
              </a>

              <br />

              <br />

              <a
                type="button"
                class="btn btn-light"
                href="https://www.linkedin.com/company/kenwynaccounting"
                target="_blank"
              >
                <b>FOLLOW US</b>
                &nbsp;
                <i class="fa fa-linkedin-square" style="font-size: 20px"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row pt-5 pl-4">
      <div class="col-md-6 col-sm-12 col-lg-6">
        <h5><b></b></h5>

        <div class="text-secondary text-justify">
          <p class="pt-3 pl-2">
            Interested in Kenwyn Books? Speak to our sales team.
          </p>
        </div>

        <div class="pl-5 text-secondary">
          <p class="pt-3">
            <i
              class="fa fa-mobile fa-1x"
              aria-hidden="true"
              style="font-size: 30px"
            >
              &nbsp;&nbsp;
            </i>

            <span>+65-97223997</span>
          </p>

          <hr />

          <p class="pt-3">
            <i
              class="fa fa-envelope fa-1x"
              aria-hidden="true"
              style="font-size: 30px"
            >
              &nbsp;&nbsp;
            </i>

            <span>info@kenwynaccounting.com</span>
          </p>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-lg-6 pt- 3 pr-5">
        <h5 class="Link"><b>SEND US YOUR MESSAGE</b></h5>

        <div class="pl-2 text-secondary">
          <div class="mb-3 pt-3">
            <label for="exampleFormControlInput1" class="form-label">
              Name
            </label>

            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
            />
          </div>

          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Email
            </label>

            <input
              type="email"
              class="form-control"
              id="exampleFormControlInput1"
            />
          </div>

          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Subject
            </label>

            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
            />
          </div>

          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">
              Message
            </label>

            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>

          <div class="pt-4">
            <a
              type="button"
              class="btn btn-outline-secondary"
              href="mailto:info@kenwynaccounting.com?subject=Accounting%20Services%20Required"
            >
              <b> SEND MESSAGE</b>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid"></div>
  <div class="container-fluid">
    <div class="m-3">
      <div>
        <div
          class="row p-3 rowColor d-flex align-items-center justify-content-center"
        >
          <div class="col-md-6 col-sm-12 col-lg-6 align-middle">
            <h3 class="text-dark pt-3 pl-2">
              Exceeding Standards: Unmatched Value and Personalized Benefits
              with Our Unique Software
            </h3>

            <p class="text-secondary pt-3 pl-2">
              Our software goes beyond industry standards, offering a unique
              array of features that deliver exceptional value and personalized
              benefits for you.
            </p>
          </div>

          <div class="col-md-6 col-sm-12 col-lg-6 pr-5">
            <div class="row text-dark">
              <div class="col-md-6 col-sm-12 col-lg-6 text-center">
                <div class="card">
                  <div class="card-body">
                    <i
                      class="fa fa-user-plus fa-2x"
                      aria-hidden="true"
                      style="color: #028237"
                    ></i>

                    <p><b>Unlimited Company /Users Creation</b></p>

                    <p class="hadcolor">
                      Offering unmatched flexibility and scalability to cater to
                      your diverse data management requirements.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-12 col-lg-6 text-center">
                <div class="card">
                  <div class="card-body">
                    <i
                      class="fa fa-server fa-2x"
                      aria-hidden="true"
                      style="color: #028237"
                    ></i>
                    <p>
                      <b>
                        Multiple Deployment Options: On-Premise, Cloud, or
                        Hybrid
                      </b>
                    </p>

                    <p class="hadcolor">
                      Empower your business with the flexibility to choose the
                      deployment model that fits your needs.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-12 col-lg-6 text-center">
                <div class="card">
                  <div class="card-body">
                    <i
                      class="fa fa-usd fa-2x"
                      aria-hidden="true"
                      style="color: #028237"
                    ></i>
                    <p><b>Multi-Currency Support</b></p>

                    <p class="hadcolor">
                      Easily handle transactions in various currencies with our
                      software's powerful multi-currency feature.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-12 col-lg-6 text-center">
                <div class="card">
                  <div class="card-body">
                    <i
                      class="fa fa-cogs fa-2x"
                      aria-hidden="true"
                      style="color: #028237"
                    ></i>
                    <p><b>API Integration with Third-Party Solutions</b></p>

                    <p class="hadcolor">
                      Seamlessly integrate and utilize external tools to enhance
                      and streamline your business processes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="pt-5 pb-5">
      <h4 class="tecPart"><b>TECHNOLOGY PARTNERS</b></h4>

      <div class="row pt-4 pl-5" ALIGN="CENTER">
        <div class="col-md-3 col-sm-6 col-lg-3">
          <img
            class="pl-5 pt-4 text-center"
            style="width: 200px"
            src="https://www.kenwynaccounting.com/img/Technology%20Partner-1.a7858c2b.png"
          />
        </div>

        <div class="col-md-3 col-sm-6 col-lg-3">
          <img
            class="pl-5 pt-4 text-center"
            style="width: 200px"
            src="https://www.kenwynaccounting.com/img/Technology%20Partner-2.5388542b.png"
          />
        </div>

        <div class="col-md-3 col-sm-6 col-lg-3">
          <img
            class="pl-5 pt-4 text-center"
            style="width: 200px"
            src="https://www.kenwynaccounting.com/img/Technology%20Partner-3.10f11173.png"
          />
        </div>

        <div class="col-md-3 col-sm-6 col-lg-3">
          <img
            class="pl-5 pt-4 text-center"
            style="width: 200px"
            src="https://www.kenwynaccounting.com/img/Technology%20Partner-4.58afdde6.png"
          />
        </div>
      </div>
    </div>
  </div>

  <!--contact us page-->
</template>

<script>
export default { name: "Home", components: {} };
</script>

<style scoped>
h5,
h6 {
  margin: 5px;
  padding: 5px;
  color: #040404;
}
h4 {
  text-align: center;
  color: #000000;
  padding-top: 20px;
}
h2 {
  text-align: center;
  color: #099118;
  letter-spacing: 0.1px;
}
.socialIcon {
  text-align: center;
}
.socialMedia {
  color: rgba(234, 229, 229, 0.551);
  text-align: center;
  font-size: 14px;
  padding-top: 30px;
}

.socialMediah {
  color: rgb(255, 255, 255);
  text-align: center;
  padding-top: 30px;
  font-size: 20px;
}
.card-img-top {
  max-width: 350px;
}
.card-title {
  color: #099118;
}
.container_card {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 40px 40px 40px;
  margin: 5px;
  background-color: #f8f8f8a1;
}
.rowColor {
  background-color: #099118;
}
.rowColorTwo {
  background-color: #096514;
}

.inner {
  padding: 10px;
  transition: 0.5s ease-in-out;
}
.inner:hover {
  transform: scale(1.1);
}
ul {
  text-align: left;
  margin: 0.5;
}

li {
  list-style: none;
  margin-bottom: 15px;
}
.container_image {
  background: #099118;
}
.carousel-item {
  height: 32rem;
  background: #fff;
  color: darkblue;
  position: relative;
  padding-top: 100px;
  border-radius: 0;
}
.container_text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 30px;
}
.slide_text {
  background: #099118;
}
.container_about_dash {
  background: rgb(97, 31, 31);
}
.column {
  float: left;
  width: 10%;
  padding: 5px;
  vertical-align: middle;
  text-align: center;
  align-items: center;
}
.row_logo::after {
  content: "";
  clear: both;
  display: table;
}

.column_com_1 {
  box-sizing: border-box;
  background: #0f206c;
  width: 100%;
  display: inline-block;
  border-radius: 0;
  padding: 25px 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background-position: 98% 100%;
  background-size: 33%;
  color: #fff;
}
.conQus {
  color: white;
}
.conQusTwo {
  color: white;
  font-size: 30px;
  text-align: center;
}
.rowClr {
  color: white;
}
.head {
  font-size: 60px;
  color: white;
  text-align: center;
}
.column_com_2 {
  padding: 25px 10px 25px 10px;
  background-color: #f1f0f6;
  border-radius: 0px 10px 10px 00px;
  background-position: 98% 100%;
  background-size: 33%;
}

.left_side {
  background: red;
}
.right_side {
  background: black;
}
.trial {
  color: #fff;
  cursor: pointer;
  background-color: transparent;
}
.card:hover {
  background: rgb(247, 244, 244);
}
.card {
  box-sizing: border-box;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-position: 98% 100%;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.card_one {
  margin-bottom: 12px;
}
.card_two {
  box-sizing: content-box;
  border: solid rgb(223, 217, 217);
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 10px;
  margin-left: 15px;
  margin-bottom: 12px;
  background-position: 98% 100%;
}
.card_slide {
  border: 2px solid #f1effa;
  border-radius: 20px !important;
}

.head {
  font-size: 60px;
  color: white;
  text-align: center;
}

.img-container {
  max-width: 100%;
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.first-slide {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  z-index: 0;
}

.bg-text {
  color: white;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 90%;
  padding: 15px;
  text-align: center;
  margin-top: 30px;
}

.rowColor {
  background-color: #f9fafb;
}

.hadcolor {
  color: #028237;
}

.text-head {
  font-size: 3rem;
}
.buttonService {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}
.contactUs {
  text-decoration: none;
  color: white;
}
.paraHead {
  color: #fff;
  font-size: 1rem;
}

.cardcontent1 {
  margin-bottom: 80px;
}

.cardcontent2 {
  margin-bottom: 40px;
}

.cardcontent3 {
  margin-bottom: 100px;
}

@media (max-width: 576px) {
  .text-head {
    font-size: 2.5rem;
  }
  .paraHead {
    font-size: 1rem;
  }
  .buttonService {
    font-size: 0.85rem;
    padding: 8px 16px;
  }
}

@media only screen and (max-width: 600px) {
  .first-slide {
    max-width: 100%;
    height: 700px;
  }
}
@media only screen and (max-width: 300px) {
  .first-slide {
    max-width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .first-slide {
    max-width: 100%;
    height: 900px;
  }
}
</style>
