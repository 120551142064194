<template>
  <!--features image-->
  <section class="main-hero-image">
    <div class="overlay">
      <div class="hero-text">
        <h1>CorpSec</h1>
        <p></p>
      </div>
    </div>
  </section>

  <div class="rowColor">
    <div class="container">
      <div class="row align-items-center pt-3 text-center text-md-start">
        <div class="col-md-6 col-sm-12 col-lg-6 maintext">
          <h1 class="fstheding text-md-start text-center">
            Streamline Corporate <br />
            Compliance & Governance
          </h1>
          <p class="acc_text">
            Managing corporate compliance can be complex and time-consuming. Our
            Corporate Secretarial Software simplifies statutory filings,
            document management, and company records, ensuring seamless
            corporate governance.
          </p>
        </div>
        <div
          class="col-md-6 col-sm-12 col-lg-6 maintext d-flex justify-content-center"
        >
          <img src="@/assets/CorpSec.png" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>

  <div class="secontColor">
    <div class="container">
      <div class="row align-items-center justify-content-center pt-3 pb-5">
        <h1 class="keyfont">Key Features</h1>
        <div class="col-md-4 col-sm-12 col-lg-4 pt-4">
          <div class="card custom-card shadow" style="height: 15rem">
            <div class="card-body text-center">
              <div class="icon-container">
                <i class="fa fa-tasks fa-4x icon-box" aria-hidden="true"></i>
              </div>
              <h5 class="card-title pt-2">
                <b>Automated Compliance Tracking</b>
              </h5>
              <p class="card-text">
                Stay on top of deadlines with automated reminders for filings,
                renewals, and annual returns.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 col-lg-4 pt-4">
          <div class="card custom-card shadow" style="height: 15rem">
            <div class="card-body text-center">
              <div class="icon-container">
                <i
                  class="fa fa-folder-open fa-4x icon-box"
                  aria-hidden="true"
                ></i>
              </div>
              <h5 class="card-title pt-2"><b>Company Records Management</b></h5>
              <p class="card-text">
                Maintain detailed records of directors, shareholders, and
                company structures in a centralized platform.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 col-lg-4 pt-4">
          <div class="card custom-card shadow" style="height: 15rem">
            <div class="card-body text-center">
              <div class="icon-container">
                <i class="fa fa-edit fa-4x icon-box" aria-hidden="true"></i>
              </div>
              <h5 class="card-title pt-2"><b>Document Generation </b></h5>
              <p class="card-text">
                Generate resolutions and statutory forms with ease.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 col-lg-4 pt-4">
          <div class="card custom-card shadow" style="height: 15rem">
            <div class="card-body text-center">
              <div class="icon-container">
                <i class="fa fa-sitemap fa-4x icon-box" aria-hidden="true"></i>
              </div>
              <h5 class="card-title pt-2"><b>Multi-Company Management </b></h5>
              <p class="card-text">
                Manage multiple entities from a single dashboard.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 col-lg-4 pt-4">
          <div class="card custom-card shadow" style="height: 15rem">
            <div class="card-body text-center">
              <div class="icon-container">
                <i class="fa fa-cloud fa-4x icon-box" aria-hidden="true"></i>
              </div>
              <h5 class="card-title pt-2"><b>Secure Cloud-Based Access </b></h5>
              <p class="card-text">
                Access your data from anywhere with robust security protocols.
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4 col-sm-12 col-lg-4 pt-4">
          <div class="card custom-card shadow" style="height: 15rem">
            <div class="card-body text-center">
              <div class="icon-container">
                <i
                  class="fa fa-chart-line fa-4x icon-box"
                  aria-hidden="true"
                ></i>
              </div>
              <h5 class="card-title pt-2">
                <b>Integrated Accounting & Reports </b>
              </h5>
              <p class="card-text">
                Sync with accounting systems for seamless financial tracking and
                reporting.
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row align-items-center pt-5 text-md-start text-center">
      <h1 class="whyheding pb-3">Why Choose Us?</h1>
      <div class="col-md-4 col-sm-12 col-lg-4 border-end">
        <h4>Time-Saving Automation</h4>
        <p class="acc_text">
          Eliminate manual paperwork and reduce administrative workload.
        </p>
      </div>
      <div class="col-md-4 col-sm-12 col-lg-4 border-end">
        <h4>Regulatory Compliance</h4>
        <p class="acc_text">
          Stay updated with the latest corporate regulations and compliance
          requirements.
        </p>
      </div>
      <div class="col-md-4 col-sm-12 col-lg-4">
        <h4>User-Friendly Interface</h4>
        <p class="acc_text">
          Navigate easily with an intuitive and well-structured dashboard.
        </p>
      </div>
    </div>
  </div>
  <section class="hero-image mt-3">
    <div class="container">
      <div class="row hading-text pt-5">
        <div class="col-md-12 col-sm-12 col-lg-12">
          <h4>Pricing & Subscription</h4>
          <p class="normalext">
            Our corporate secretarial software is available at competitive
            pricing to suit businesses of all sizes. Contact us for a customized
            quote.
          </p>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-12">
          <h4>Get Started Today!</h4>
          <p class="normalext">
            Enhance your corporate governance with our powerful and easy-to-use
            Corporate Secretarial Software.
          </p>
          <div class="buttonFolow">
            <a
              type="button"
              class="btn btn-light"
              href="https://api.whatsapp.com/send?phone=6597223997"
              target="_blank"
            >
              <b>
                Contact Us &nbsp;
                <!-- <i class="fa fa-facebook-square" style="font-size: 20px"></i> -->
              </b>
            </a>
            &nbsp; Or &nbsp;

            <a
              type="button"
              class="btn btn-light"
              href="https://api.whatsapp.com/send?phone=6597223997"
              target="_blank"
            >
              <b>Schedule a Demo</b>
              &nbsp;
              <!-- <i class="fa fa-linkedin-square" style="font-size: 20px"></i> -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.main-hero-image {
  height: 100vh;
  background-size: cover;
  width: 100%;
  height: 430px;
  background-position: center;
  margin-top: 72px;
  background-image: url("@/assets/ABOUT-US.webp");
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-text {
  color: #fff;
  text-align: center;
}

.maintext {
  text-align: justify;
}

.hero-image {
  height: 100vh;
  background-size: cover;
  width: 100%;
  height: 350px;
  background-position: left;

  background-image: url("@/assets/GreenBackground.jpg");
}

.hading-text {
  color: #fff;
  text-align: left;
}

.normalext {
  color: #fff;
}
.container-fluid {
  background: rgb(240, 238, 238);
  padding-top: 100px;
}
.keyfont {
  color: rgb(8, 147, 29);
  text-align: center;
}
.fstheding {
  color: rgb(8, 147, 29);
}
.whyheding {
  color: rgb(8, 147, 29);
  text-align: center;
}
.rowColor {
  background-color: #ffffff;
}

.secontColor {
  background-color: #f0f0f0;
}
.acc_text {
  color: rgb(0, 0, 0);
  font-size: 17px;
}

.img-container {
  max-width: 100%;
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}

.head {
  font-size: 50px;
  text-align: center;
}
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

.btn-demo {
  background-color: rgb(252, 250, 250);
  color: rgb(26, 6, 112);
  border-color: rgb(26, 6, 112);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}
.container-fluid-hr {
  background: rgb(255, 255, 255);
  padding-top: 100px;
  padding-bottom: 150px;
}

.icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  /* border: 3px solid #00a301; */
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  background-color: #c7eec7;
}

.icon-container i {
  color: #046904;
  transition: color 0.3s ease-in-out;
}

/* Hover effect when the card is hovered */
.custom-card:hover .icon-container {
  background-color: #046904;
}

.custom-card:hover .icon-container i {
  color: white;
}
</style>
