<template>
  <!-- CHAT ICON -->
  <div id="mavenChatIcon" class="maven-chat-icon">
    <i class="fas fa-comments"></i>
  </div>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="mavenChatContainer"
    aria-labelledby="mavenChatContainerLabel"
  >
    <div class="offcanvas-header bg-success text-white">
      <h5 id="mavenChatContainerLabel" class="offcanvas-title">Chat</h5>
      <button
        type="button"
        class="btn-close text-white"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div
        id="mavenChatMessages"
        class="chat-body overflow-auto"
        style="max-height: 1000px"
      ></div>
      <div class="input-group mt-3">
        <input
          type="text"
          id="txtChatQuestion"
          class="form-control"
          placeholder="Type your question..."
        />
        <button class="btn btn-outline-success" id="btnCommonAskQuestion">
          <span
            class="spinner-border spinner-border-sm loading-spinner me-2"
            id="loadingSpinner"
          ></span>
          <i class="fas fa-paper-plane"></i> Ask
        </button>
      </div>
    </div>
  </div>
  <!-- CHAT ICON -->
</template>

<!-- SCRIPT FOR CHAT AI -->
<script>
import { ref, onMounted } from "vue";
export default {
  setup() {
    onMounted(() => {
      document.addEventListener("DOMContentLoaded", function () {
        document
          .getElementById("mavenChatIcon")
          .addEventListener("click", function () {
            var mavenChatContainer = new bootstrap.Offcanvas(
              document.getElementById("mavenChatContainer")
            );
            mavenChatContainer.show();
            document.getElementById("mavenChatIcon").style.display = "none";
          });

        document
          .getElementById("mavenChatContainer")
          .addEventListener("hidden.bs.offcanvas", function () {
            document.getElementById("mavenChatIcon").style.display = "flex";
          });

        document
          .getElementById("btnCommonAskQuestion")
          .addEventListener("click", function () {
            var txtQuestion = document
              .getElementById("txtChatQuestion")
              .value.trim();
            if (!txtQuestion) return;

            var btnAsk = document.getElementById("btnCommonAskQuestion");
            var loadingSpinner = document.getElementById("loadingSpinner");

            btnAsk.disabled = true;
            loadingSpinner.style.display = "inline-block";

            var formData = new FormData();
            formData.append("txtQuestion", txtQuestion);

            fetch("https://maven.maritechsoftware.com/chat-ask-question", {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((data) => {
                loadingSpinner.style.display = "none";
                btnAsk.disabled = false;
                if (data.status === "success") {
                  var responseText = data.data;
                  var mavenChatMessages =
                    document.getElementById("mavenChatMessages");

                  mavenChatMessages.innerHTML =
                    `<div class="chat-message-wrapper"><div class="chat-bubble user-message bg-success text-white">${txtQuestion}</div></div>` +
                    `<div class="chat-message-wrapper"><div class="chat-bubble bot-message bg-light text-dark pt-3">${responseText}</div></div>` +
                    mavenChatMessages.innerHTML;

                  document.getElementById("txtChatQuestion").value = "";
                }
              })
              .catch((error) => {
                loadingSpinner.style.display = "none";
                btnAsk.disabled = false;
                console.error("Fetch Error: ", error);
              });
          });
      });
    });
  },
};
</script>
<!-- SCRIPT FOR CHAT AI -->

<!-- STYLES FOR CHAT AI -->
<style>
.maven-chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #12a116;
  color: white;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  cursor: pointer;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}
.maven-chat-icon:hover {
  transform: scale(1.1);
}
.maven-chat-icon i {
  font-size: 24px;
}
.offcanvas-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.chat-body {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}
.chat-bubble {
  max-width: 75%;
  padding: 10px 15px;
  border-radius: 10px;
  word-wrap: break-word;
  font-family: "Arial", sans-serif;
}
.user-message {
  background-color: #12a116;
  color: white;
  border-radius: 15px 15px 0 15px;
  align-self: flex-end;
}
.bot-message {
  background-color: #f8f9fa;
  color: black;
  border-radius: 15px 15px 15px 0;
  align-self: flex-start;
}
.input-group {
  width: 100%;
}
.loading-spinner {
  display: none;
}
</style>
<!-- STYLES FOR CHAT AI -->
